//REMINDER: THERE ARE TWO SECTIONS FOR DESKTOP AND MOBILE VIEW

//REMINDER to disable 'show deals' if a shop doesnt have any product on sale
/*
	className={
		shop.shop_gotdeals
			? 'shop-pages-item green-page'
			: 'shop-pages-item page-disabled'
	}
*/

import React from 'react'
import parse from 'html-react-parser'
import { Link } from 'react-router-dom'
import moment from 'moment'
import numbro from 'numbro'
//import InnerImageZoom from 'react-inner-image-zoom'
import toast from 'react-hot-toast'
import { Menu, Transition } from '@headlessui/react'
import keywordextract from './keywordextract'
// keywordextract.run(product, shop.shop_name)

export default ({
    result,
    userCountry,
    trackClickThrough,
    setSearchTerm,
    searchTerm,
    setFilter,
}) => {
    var resultsPerPage = 58

    /* docTitle construciton - A MIRROR OF THIS EXISTS IN INDEX.JS */
    let currentHref = window.location.href

    let keywordsExist = /q=(.*)&size/.test(currentHref)

    let productTypeExist =
        /product_type&filters%5B0%5D%5Bvalues%5D%5B0%5D=(.*)&filters%5B0%5D%5Btype%5D=all/.test(
            currentHref
        )

    let pageNumberExist = /current=n_/.test(currentHref)

    var docTitleVars = { keywords: '', product_type: '', pageNumber: '' }

    if (keywordsExist) {
        docTitleVars.keywords = decodeURIComponent(
            currentHref.match(/q=(.*)&size/)[1]
        )
    }

    if (pageNumberExist) {
        docTitleVars.pageNumber =
            '- Page ' +
            decodeURIComponent(currentHref.match(/current=n_(.*)_n&/)[1])
    }

    if (productTypeExist) {
        docTitleVars.product_type =
            '- ' +
            decodeURIComponent(
                currentHref.match(
                    /product_type&filters%5B0%5D%5Bvalues%5D%5B0%5D=(.*)&filters%5B0%5D%5Btype%5D=all/
                )[1]
            )
    }

    document.title = `${docTitleVars.keywords} ${docTitleVars.product_type} ${docTitleVars.pageNumber} - Merchlook.com`

    /* docTitle construction END */

    //function for inserting track-returns at the end of shop_pages
    function sortForTrackReturns(arr) {

        let indexOfTrackReturns = arr.indexOf('track-returns')

        if (indexOfTrackReturns !== -1) {
            arr.splice(indexOfTrackReturns, 1)
            arr.push('track-returns')
            return arr
        } else {
            return arr
        }
    }

    let product = JSON.parse(result.product_data.raw)
    let shop = JSON.parse(result.shop_data.raw)
    let id = JSON.parse(result.id.raw)
    let options_count = product.options_count
    let followers = numbro(shop.shop_ig_count).format({
        optionalMantissa: true,
        average: true,
        mantissa: 1,
        lowPrecision: false,
    })

    /* turn everything to string to squash bugs with numbers*/
    shop.shop_desc =
        shop.shop_desc !== undefined ? shop.shop_desc.toString() : ''
    product.title = product.title !== undefined ? product.title.toString() : ''
    product.product_type =
        product.product_type !== undefined
            ? product.product_type.toString()
            : ''
    product.vendor =
        product.vendor !== undefined ? product.vendor.toString() : ''
    shop.shop_ig_user =
        shop.shop_ig_user !== undefined ? shop.shop_ig_user.toString() : ''
    shop.shop_title =
        shop.shop_title !== undefined ? shop.shop_title.toString() : ''
    product.body_short =
        product.body_short !== undefined ? product.body_short.toString() : ''

    let body_short_sliced = product.body_short
        .slice(0, 140)
        .replace(/</gi, '')
        .replace(/>/gi, '')
    let body_short_modified = []

    //if !isLoading === cachedIsLoading

    let cleanSearchTerms = []

    function decodeHTML(str) {
        let txt = document.createElement('textarea')

        txt.innerHTML = str

        return txt.value
    }

    for (let term of searchTerm.split(' ')) {
        if (term[0] !== '-' && term.length > 2) {
            if (term !== '') {
                let cleanTerm = term
                    .replace(/"/gi, '')
                    .replace(/\+/gi, '')
                    .replace(/\(/gi, '')
                    .replace(/\)/gi, '')
                    .replace(/\{/gi, '')
                    .replace(/\}/gi, '')
                    .replace(/\[/gi, '')
                    .replace(/\]/gi, '')

                if (cleanTerm !== '') {
                    cleanSearchTerms.push(new RegExp(cleanTerm, 'gi'))
                }
            }
        }
    }

    //store isLoading in cache or browserstorage, and use it as a condition
    //
    //we need to find and store all indices of matchups and generate the actual string before passing on to parse() to avoid <stro<stron>ng> errors

    let collectiveRegex = []

    let regexDictionary = {}

    for (let termRegex of cleanSearchTerms) {
        let arrayResults = [...body_short_sliced.matchAll(termRegex)]

        for (let result of arrayResults) {
            collectiveRegex.push(result)
        }
    }

    for (let item of collectiveRegex) {
        regexDictionary[item.index] = item[0]
    }

    let sortedIndices = Object.keys(regexDictionary).sort((a, b) => a - b)

    for (let i = 0; i < sortedIndices.length; i++) {
        // <strong>wefrwef</strong>wefwjenfkjne <<< EACH PIECE
        //[2, 5, 64]
        //{2: 'enhance', 5: 'ok', 64: 'wonder'}
        if (sortedIndices.length > 0) {
            if (i === 0) {
                body_short_modified.push(
                    body_short_sliced.slice(0, sortedIndices[i])
                )
            }
            body_short_modified.push(
                '<span className="highlight-text">' +
                    regexDictionary[sortedIndices[i]] +
                    '</span>'
            )

            body_short_modified.push(
                body_short_sliced.slice(
                    Number(sortedIndices[i]) +
                        regexDictionary[sortedIndices[i]].length,
                    sortedIndices[i + 1]
                )
            )
        }
    }

    function RenderCountry(currency) {
        switch (currency) {
            case 'CAD':
                return 'Canada'
            case 'USD':
                return 'USA'
            case 'GBP':
                return 'UK'
            case 'EUR':
                return 'Europe'
            case 'AUD':
                return 'Australia'
            default:
                return ''
        }
    }

    var messengerUser = null

    if (
        shop.shop_fb_user !== undefined &&
        (shop.shop_fb_user !== null || shop.shop_fb_user !== shop.shop_ig_user)
    ) {
        messengerUser = shop.shop_fb_user
    } else {
        messengerUser = shop.shop_ig_user
    }

    followers =
        followers.slice(0, followers.length - 1) +
        followers[followers.length - 1].toUpperCase()

    options_count = Object.keys(options_count).filter((key) => {
        return !(key.toLowerCase() === 'title')
    })

    function capitalize(str) {
        if (str === undefined) return ''
        const lower = str.toLowerCase()
        return str.charAt(0).toUpperCase() + lower.slice(1)
    }

    function renderFlag(currency) {
        const flags = {
            aud: '🇦🇺',
            eur: '🇪🇺',
            gbp: '🇬🇧',
            inr: '🇮🇳',
            mxn: '🇲🇽',
            dkk: '🇩🇰',
            hkd: '🇭🇰',
            aed: '🇦🇪',
            jpy: '🇯🇵',
            sgd: '🇸🇬',
            zar: '🇿🇦',
            pln: '🇵🇱',
            idr: '🇮🇩',
            nok: '🇳🇴',
            myr: '🇲🇾',
            try: '🇹🇷',
            brl: '🇧🇷',
            clp: '🇨🇱',
            cop: '🇨🇴',
            kwd: '🇰🇼',
            krw: '🇰🇷',
            ars: '🇦🇷',
        }

        return (
            <span className="sui-result-content-flag">
                {flags[currency.toLowerCase()]}
            </span>
        )
    }

    function findFinancingLink(pages) {
        let title = false

        Object.keys(pages).forEach((item, index, arr) => {
            if (item.includes('financ')) {
                title = item
            }
        })

        return title
        //return false or the page title string 'financing-options'
    }

    return (
        <div className="sui-result">
            <div className="sui-result-image sui-result-image-mobile">
                <Menu>
                    <Menu.Button
                        className="sui-result-content-productlogo-button"
                        as="div"
                    >
                        {product.is_bestselling === 1 && (
                            <span className="bestseller-tag">Hot Pick 🔥</span>
                        )}

                        {options_count.length > 0 && (
                            <svg
                                className="multi-options-tag"
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#fff"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <rect x="3" y="3" width="7" height="7"></rect>
                                <rect x="14" y="3" width="7" height="7"></rect>
                                <rect x="14" y="14" width="7" height="7"></rect>
                                <rect x="3" y="14" width="7" height="7"></rect>
                            </svg>
                        )}

                        <img
                            className="sui-result-image-productimage"
                            src={
                                product.images.length !== 0
                                    ? product.images[0].src
                                    : 'https://photos.merchlook.com/shop-logos/noimage.png'
                            }
                            alt={'product-image'}
                        />
                    </Menu.Button>
                    <Menu.Items className="sui-result-content-product-pages product-pages-mobile">
                        {options_count.length > 0 && (
                            <div className="product-pages-info">
                                <span className="product-pages-title">
                                    <svg
                                        className="multi-options-tag-productinfo"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="#fff"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    >
                                        <rect
                                            x="3"
                                            y="3"
                                            width="7"
                                            height="7"
                                        ></rect>
                                        <rect
                                            x="14"
                                            y="3"
                                            width="7"
                                            height="7"
                                        ></rect>
                                        <rect
                                            x="14"
                                            y="14"
                                            width="7"
                                            height="7"
                                        ></rect>
                                        <rect
                                            x="3"
                                            y="14"
                                            width="7"
                                            height="7"
                                        ></rect>
                                    </svg>
                                    Options
                                </span>
                                {'options_hashmap' in product &&
                                    product.options_hashmap !== undefined &&
                                    Object.keys(product.options_hashmap).map(
                                        (option) => {
                                            let alt_key = capitalize(
                                                option.toLowerCase()
                                            )
                                            return (
                                                <div
                                                    className="options-container"
                                                    key={option}
                                                >
                                                    <div className="options-title">
                                                        {alt_key}
                                                    </div>
                                                    <div>
                                                        {product.options_hashmap[
                                                            option
                                                        ]
                                                            .slice(0, 7)
                                                            .map((key) => {
                                                                return (
                                                                    <span
                                                                        className="options-tags"
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        {key}
                                                                    </span>
                                                                )
                                                            })}
                                                        {product
                                                            .options_hashmap[
                                                            option
                                                        ].length > 7 && (
                                                            <span className="options-tags">
                                                                and more...
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        }
                                    )}
                            </div>
                        )}

                        <div className="product-pages-links">
                            <Menu.Item>
                                {({ active }) => (
                                    <a href={product.product_link}>
                                        <div className="product-pages-item">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="#c2995b"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0" />
                                            </svg>
                                            <span className="product-pages-item-text">
                                                open item
                                            </span>
                                        </div>
                                    </a>
                                )}
                            </Menu.Item>

                            <Menu.Item>
                                {({ active }) => (
                                    <a
                                        href={`?q=${keywordextract.run(
                                            product,
                                            shop.shop_title
                                        )} -${
                                            shop.shop_ig_user
                                        }&size=n_${resultsPerPage}_n`}
                                    >
                                        <div className="product-pages-item green-page">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="#7cb683"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <circle
                                                    cx="11"
                                                    cy="11"
                                                    r="8"
                                                ></circle>
                                                <line
                                                    x1="21"
                                                    y1="21"
                                                    x2="16.65"
                                                    y2="16.65"
                                                ></line>
                                            </svg>
                                            <span className="product-pages-item-text">
                                                show related items
                                            </span>
                                        </div>
                                    </a>
                                )}
                            </Menu.Item>

                            <Menu.Item>
                                {({ active }) => (
                                    <span
                                        onClick={() => {
                                            toast(`Access Denied`, {
                                                icon: '🐮',
                                                style: {
                                                    borderRadius: '10px',
                                                    background: '#333',
                                                    color: '#fff',
                                                    fontWeight: 'bold',
                                                },
                                            })
                                        }}
                                    >
                                        <div className="product-pages-item page-disabled">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="#fff"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
                                            </svg>
                                            <span className="product-pages-item-text">
                                                watch item
                                            </span>
                                        </div>
                                    </span>
                                )}
                            </Menu.Item>

                            <Menu.Item>
                                {({ active }) => (
                                    <span>
                                        <div className="product-pages-item close-page close-page-mobile">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="16"
                                                height="16"
                                                viewBox="0 0 24 24"
                                                fill="none"
                                                stroke="#000"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <circle
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                ></circle>
                                                <line
                                                    x1="15"
                                                    y1="9"
                                                    x2="9"
                                                    y2="15"
                                                ></line>
                                                <line
                                                    x1="9"
                                                    y1="9"
                                                    x2="15"
                                                    y2="15"
                                                ></line>
                                            </svg>
                                            <span className="product-pages-item-text">
                                                close
                                            </span>
                                        </div>
                                    </span>
                                )}
                            </Menu.Item>
                        </div>
                    </Menu.Items>
                </Menu>

                <div className="sui-result-image-mobile-body">
                    <div className="sui-result-content-shop">
                        <div className="sui-result-content-shoplogo-container">
                            <Menu>
                                <Menu.Button className="sui-result-content-shoplogo-button">
                                    <img
                                        className={shop.shop_gotdeals ? `sui-result-content-shoplogo shoplogo-gotdeals` : `sui-result-content-shoplogo`}
                                        src={`https://photos.merchlook.com/shop-logos/${shop.shop_ig_user}.jpg`}
                                    />
                                </Menu.Button>
                                <Menu.Items className="sui-result-content-shop-pages shop-pages-mobile">
                                    <div className="shop-pages-links">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href={`https://app-va.tiktokv.com/redirect/?redirect_url=snssdk1233%3A%2F%2Fsearch%3Fkeyword%3D${shop.shop_ig_user}%26params_url%3Dhttps%253A%252F%252Fwww.tiktok.com%252Finbox%26refer%3Dweb%26needlaunchlog%3D1%26ug_medium%3Dfe_component%26jump_time%3D1671808281996%26page_name%3Dgeneral_search%26gd_label%3Dclick_wap_search%26refer%3Ddirect%26wid%3D7180361248775620101%26search_keyword%3D${shop.shop_ig_user}%26search_type%3Dgeneral%26enter_from%3Dhomepage_hot_web%26enter_method%3Dsearch_sug%26search_sug_type%3Dnormal_sug&dl=https%3A%2F%2Fgo.onelink.me%2FbIdt%3Faf_adset_id%3D0_unknown%26domain_source%3Dtiktok%26af_dp%3Dsnssdk1233%253A%252F%252Fsearch%253Fkeyword%253D${shop.shop_ig_user}%2526params_url%253Dhttps%25253A%25252F%25252Fwww.tiktok.com%25252Finbox%2526refer%253Dweb%2526needlaunchlog%253D1%2526ug_medium%253Dfe_component%2526jump_time%253D1671808281996%2526page_name%253Dgeneral_search%2526gd_label%253Dclick_wap_search%2526refer%253Ddirect%2526wid%253D7180361248775620101%2526search_keyword%253D${shop.shop_ig_user}%2526search_type%253Dgeneral%2526enter_from%253Dhomepage_hot_web%2526enter_method%253Dsearch_sug%2526search_sug_type%253Dnormal_sug%26pid%3Dtiktokweb%26jump_time%3D1671808281996%26af_ad%3Dclick_wap_search%26af_siteid%3Dmobile%26c%3Dgeneral_search%26af_adset%3Ddirect%26af_ad_id%3Dno_referrer%26canonical%3Dhttps%253A%252F%252Fwww.tiktok.com%252Fsearch%26wid%3D7180361248775620101&decode_once=1`}
                                                >
                                                    <div className="shop-pages-item">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#84a7c4"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <rect
                                                                x="2"
                                                                y="2"
                                                                width="20"
                                                                height="20"
                                                                rx="2.18"
                                                                ry="2.18"
                                                            ></rect>
                                                            <line
                                                                x1="7"
                                                                y1="2"
                                                                x2="7"
                                                                y2="22"
                                                            ></line>
                                                            <line
                                                                x1="17"
                                                                y1="2"
                                                                x2="17"
                                                                y2="22"
                                                            ></line>
                                                            <line
                                                                x1="2"
                                                                y1="12"
                                                                x2="22"
                                                                y2="12"
                                                            ></line>
                                                            <line
                                                                x1="2"
                                                                y1="7"
                                                                x2="7"
                                                                y2="7"
                                                            ></line>
                                                            <line
                                                                x1="2"
                                                                y1="17"
                                                                x2="7"
                                                                y2="17"
                                                            ></line>
                                                            <line
                                                                x1="17"
                                                                y1="17"
                                                                x2="22"
                                                                y2="17"
                                                            ></line>
                                                            <line
                                                                x1="17"
                                                                y1="7"
                                                                x2="22"
                                                                y2="7"
                                                            ></line>
                                                        </svg>

                                                        <span className="shop-pages-item-text tiktok-page">
                                                            open tiktok
                                                        </span>
                                                    </div>
                                                </a>
                                            )}
                                        </Menu.Item>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href={`https://instagram.com/${shop.shop_ig_user}`}
                                                >
                                                    <div className="shop-pages-item">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#84a7c4"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <rect
                                                                x="3"
                                                                y="3"
                                                                width="18"
                                                                height="18"
                                                                rx="2"
                                                            />
                                                            <circle
                                                                cx="8.5"
                                                                cy="8.5"
                                                                r="1.5"
                                                            />
                                                            <path d="M20.4 14.5L16 10 4 20" />
                                                        </svg>
                                                        <span className="shop-pages-item-text instagram-page">
                                                            open instagram
                                                        </span>
                                                    </div>
                                                </a>
                                            )}
                                        </Menu.Item>

                                        <Menu.Item>
                                            <a
                                                href={`/?q=%2B%40${shop.shop_ig_user}&size=n_${resultsPerPage}_n`}
                                            >
                                                <div className="shop-pages-item green-page">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#5f83a1"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <circle
                                                            cx="11"
                                                            cy="11"
                                                            r="8"
                                                        ></circle>
                                                        <line
                                                            x1="21"
                                                            y1="21"
                                                            x2="16.65"
                                                            y2="16.65"
                                                        ></line>
                                                    </svg>

                                                    <span className="shop-pages-item-text">
                                                        show all products
                                                    </span>
                                                </div>
                                            </a>
                                        </Menu.Item>
                                        {shop.shop_gotdeals === 0 ? (
                                            <Menu.Item>
                                                <span
                                                    onClick={() => {
                                                        toast(
                                                            `No deals found for @${shop.shop_ig_user}`,
                                                            {
                                                                icon: '🐮',
                                                                style: {
                                                                    borderRadius:
                                                                        '10px',
                                                                    background:
                                                                        '#333',
                                                                    color: '#fff',
                                                                    fontWeight:
                                                                        'bold',
                                                                },
                                                            }
                                                        )
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            'shop-pages-item page-disabled'
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#5f83a1"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <circle
                                                                cx="11"
                                                                cy="11"
                                                                r="8"
                                                            ></circle>
                                                            <line
                                                                x1="21"
                                                                y1="21"
                                                                x2="16.65"
                                                                y2="16.65"
                                                            ></line>
                                                        </svg>

                                                        <span className="shop-pages-item-text">
                                                            show deals
                                                        </span>
                                                    </div>
                                                </span>
                                            </Menu.Item>
                                        ) : (
                                            <Menu.Item>
                                                <a
                                                    href={`?q=%2B%40${shop.shop_ig_user}&size=n_${resultsPerPage}_n&filters%5B0%5D%5Bfield%5D=is_onsale&filters%5B0%5D%5Bvalues%5D%5B0%5D=n_1_n&filters%5B0%5D%5Btype%5D=any`}
                                                >
                                                    <div
                                                        className={
                                                            'shop-pages-item deals-page'
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#5f83a1"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <circle
                                                                cx="11"
                                                                cy="11"
                                                                r="8"
                                                            ></circle>
                                                            <line
                                                                x1="21"
                                                                y1="21"
                                                                x2="16.65"
                                                                y2="16.65"
                                                            ></line>
                                                        </svg>

                                                        <span className="shop-pages-item-text">
                                                            show deals
                                                        </span>
                                                    </div>
                                                </a>
                                            </Menu.Item>
                                        )}

                                        {shop.shop_pages &&
                                            sortForTrackReturns(Object.keys(shop.shop_pages)).map(
                                                (pageTitle) => {
                                                    return (
                                                        <Menu.Item
                                                            key={pageTitle}
                                                        >
                                                            {({ active }) => (
                                                                <a
                                                                    href={
                                                                        shop
                                                                            .shop_pages[
                                                                            pageTitle
                                                                        ]
                                                                    }
                                                                >
                                                                    <div className="shop-pages-item">
                                                                        {pageTitle ===
                                                                        'track-returns' ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="#c2995b"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                <path d="M17 2.1l4 4-4 4" />
                                                                                <path d="M3 12.2v-2a4 4 0 0 1 4-4h12.8M7 21.9l-4-4 4-4" />
                                                                                <path d="M21 11.8v2a4 4 0 0 1-4 4H4.2" />
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="#c2995b"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                                                                                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
                                                                            </svg>
                                                                        )}
                                                                        <span className="shop-pages-item-text">
                                                                            {pageTitle.replace(
                                                                                /-/g,
                                                                                ' '
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    )
                                                }
                                            )}
                                        <Menu.Item>
                                            <span
                                                onClick={() => {
                                                    toast(`Access Denied`, {
                                                        icon: '🐮',
                                                        style: {
                                                            borderRadius:
                                                                '10px',
                                                            background: '#333',
                                                            color: '#fff',
                                                            fontWeight: 'bold',
                                                        },
                                                    })
                                                }}
                                            >
                                                <div className="shop-pages-item page-disabled">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#444"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                                                        <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                                                    </svg>
                                                    <span className="shop-pages-item-text">
                                                        show stats
                                                    </span>
                                                </div>
                                            </span>
                                        </Menu.Item>
                                        <Menu.Item>
                                            <a
                                                href={`https://ig.me/m/${shop.shop_ig_user}`}
                                            >
                                                <div className="shop-pages-item livechat-page">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#ae7ea7"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                                    </svg>
                                                    <span className="shop-pages-item-text">
                                                        live chat
                                                    </span>
                                                </div>
                                            </a>
                                        </Menu.Item>
                                    </div>

                                    {/* change below to shop_desc*/}
                                    {shop.shop_tags !== undefined && (
                                        <div className="shop-pages-info">
                                            <div>
                                                <div className="shop-pages-shoplogo">
                                                    <img
                                                        className="sui-result-content-shoplogo shopinfo-shoplogo-big"
                                                        src={`https://photos.merchlook.com/shop-logos/${shop.shop_ig_user}.jpg`}
                                                    />
                                                </div>
                                                <Menu.Item>
                                                    <div className="shop-pages-closebutton">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="closebutton-svg"
                                                            width="30"
                                                            height="30"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#ff605c"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                            ></circle>
                                                            <line
                                                                x1="15"
                                                                y1="9"
                                                                x2="9"
                                                                y2="15"
                                                            ></line>
                                                            <line
                                                                x1="9"
                                                                y1="9"
                                                                x2="15"
                                                                y2="15"
                                                            ></line>
                                                        </svg>
                                                    </div>
                                                </Menu.Item>
                                            </div>
                                            <div className="shop-card-content">
                                                <div className="shopinfo-bio">
                                                    <strong className="shopinfo-tag-mobile">
                                                        @
                                                        {shop.shop_ig_user
                                                            .length > 14
                                                            ? `${shop.shop_ig_user.slice(
                                                                  0,
                                                                  14
                                                              )}...`
                                                            : shop.shop_ig_user}
                                                        {shop.shop_is_verified ===
                                                            1 && (
                                                            <svg
                                                                className="sui-result-content-verified"
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 14 14"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path
                                                                    d="M13.4193 7L11.9959 5.3725L12.1943 3.22L10.0884 2.74167L8.98594 0.875L7.0026 1.72667L5.01927 0.875L3.91677 2.73583L1.81094 3.20833L2.00927 5.36667L0.585938 7L2.00927 8.6275L1.81094 10.7858L3.91677 11.2642L5.01927 13.125L7.0026 12.2675L8.98594 13.1192L10.0884 11.2583L12.1943 10.78L11.9959 8.6275L13.4193 7ZM5.88844 9.75333L3.67177 7.53083L4.5351 6.6675L5.88844 8.02667L9.30094 4.6025L10.1643 5.46583L5.88844 9.75333Z"
                                                                    fill="#4a98e2"
                                                                />
                                                            </svg>
                                                        )}
                                                    </strong>

                                                    <div className="followers-container shopinfo-tag-mobile">
                                                        <span>
                                                            <svg
                                                                className="followers-logo followers-logo-shopinfo"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="14"
                                                                height="14"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                stroke="#fff"
                                                                strokeWidth="3"
                                                                strokeLinecap="round"
                                                                strokeLinejoin="round"
                                                            >
                                                                <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                                <circle
                                                                    cx="12"
                                                                    cy="7"
                                                                    r="4"
                                                                ></circle>
                                                            </svg>{' '}
                                                            <strong>
                                                                {followers}
                                                            </strong>
                                                        </span>
                                                    </div>
                                                    <span className="shopinfo-tag-mobile shop-info-locationtag">
                                                        <svg
                                                            className="location-logo location-logo-shopinfo"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#fff"
                                                            strokeWidth="3"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <circle
                                                                cx="12"
                                                                cy="10"
                                                                r="3"
                                                            />
                                                            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                                        </svg>
                                                        {RenderCountry(
                                                            shop.shop_currency
                                                        )}
                                                    </span>
                                                </div>

                                                {shop.shop_desc !==
                                                undefined ? (
                                                    <p className="shopinfo-shopdesc">
                                                        {shop.shop_desc[
                                                            shop.shop_desc
                                                                .length - 1
                                                        ] !== '.'
                                                            ? `
                                                          ${shop.shop_desc.slice(
                                                              0,
                                                              180
                                                          )}...`
                                                            : `
                                                          ${shop.shop_desc.slice(
                                                              0,
                                                              180
                                                          )}..`}
                                                    </p>
                                                ) : (
                                                    <p className="shopinfo-shopdesc">
                                                        {shop.shop_title !==
                                                        undefined
                                                            ? shop.shop_title
                                                            : '...'}
                                                    </p>
                                                )}

                                                <div className="shopinfo-tags">
                                                    {Object.keys(shop.shop_tags)
                                                        .length !== 0 && (
                                                        <div>
                                                            <strong className="populartag shop-info-blackfont">
                                                                <svg
                                                                    className="barchart-logo"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="#000"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                >
                                                                    <path d="M20.2 7.8l-7.7 7.7-4-4-5.7 5.7" />
                                                                    <path d="M15 7h6v6" />
                                                                </svg>
                                                                Top matches on{' '}
                                                                {shop.shop_ig_count <
                                                                50000
                                                                    ? 'Instagram'
                                                                    : 'Tiktok'}
                                                            </strong>
                                                        </div>
                                                    )}

                                                    <div className="shoptags-container-list">
                                                        {Object.keys(
                                                            shop.shop_tags
                                                        )
                                                            .sort(function (
                                                                a,
                                                                b
                                                            ) {
                                                                return (
                                                                    shop
                                                                        .shop_tags[
                                                                        b
                                                                    ] -
                                                                    shop
                                                                        .shop_tags[
                                                                        a
                                                                    ]
                                                                )
                                                            })
                                                            .map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    /*
                                                            if (index === 0) {
                                                                return (
                                                                    <span className="shoptags-container-list-span">
                                                                        🥇{' '}
                                                                        {item}
                                                                    </span>
                                                                )
                                                            }

                                                            if (index === 1) {
                                                                return (
                                                                    <span className="shoptags-container-list-span">
                                                                        🥈{' '}
                                                                        {item}
                                                                    </span>
                                                                )
                                                            }

                                                            if (index === 2) {
                                                                return (
                                                                    <span className="shoptags-container-list-span">
                                                                        🥉{' '}
                                                                        {item}
                                                                    </span>
                                                                )
                                                            }
															*/
                                                                    return (
                                                                        <span
                                                                            className="shoptags-container-list-span"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </span>
                                                                    )
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Menu.Items>
                            </Menu>
                            <p className="sui-result-content-shop-paragraphs followers-count-mobile">
                                <svg
                                    className="followers-logo"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#000"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                    <circle cx="12" cy="7" r="4"></circle>
                                </svg>{' '}
                                {followers}
                            </p>
                        </div>
                        <p className="sui-result-content-shop-paragraphs">
                            <strong>
                                $
                                {numbro(
                                    product[
                                        `min_price_${userCountry.toLowerCase()}`
                                    ]
                                ).format({
                                    thousandSeparated: true,
                                    mantissa: 2,
                                    optionalMantissa: true,
                                })}{' '}
                                {userCountry}
                                {Object.keys(product.options_count).length >
                                    0 &&
                                    Object.keys(product.options_count)[0] !==
                                        'Title' &&
                                    product.options_count[
                                        Object.keys(product.options_count)[0]
                                    ] > 1 &&
                                    '+'}{' '}
                            </strong>
                            {shop.shop_financing === 1 && (
                                <Menu>
                                    <Menu.Button className="sui-result-content-financing-button">
                                        <svg
                                            className="financing-tooltip"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="14"
                                            height="14"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#8C86FC"
                                            strokeWidth="3"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <rect
                                                x="2"
                                                y="4"
                                                width="20"
                                                height="16"
                                                rx="2"
                                            />
                                            <path d="M7 15h0M2 9.5h20" />
                                        </svg>
                                    </Menu.Button>
                                    <Menu.Items
                                        className="sui-result-content-financing-dropdown financing-dropdown-mobile"
                                        as="span"
                                    >
                                        <Menu.Item>
                                            {({ active }) => (
                                                <span className="financing-dropdown-text">
                                                    <svg
                                                        className="financing-flag invert-flag"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="14"
                                                        height="14"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#8C86FC"
                                                        strokeWidth="3"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <rect
                                                            x="2"
                                                            y="4"
                                                            width="20"
                                                            height="16"
                                                            rx="2"
                                                        />
                                                        <path d="M7 15h0M2 9.5h20" />
                                                    </svg>
                                                    <span>
                                                        <span className="financing-apr-text financing-apr-text-mobile">
                                                            0% APR payment plans
                                                        </span>
                                                        {shop.shop_bnpl_vendors !==
                                                            undefined &&
                                                        shop.shop_bnpl_vendors
                                                            .length > 0 ? (
                                                            <img
                                                                className={
                                                                    shop
                                                                        .shop_bnpl_vendors[0] ===
                                                                    'affirm'
                                                                        ? 'bnpl-vendor-logo vendor-logo-mobile affirm-logo'
                                                                        : `bnpl-vendor-logo vendor-logo-mobile`
                                                                }
                                                                alt="afterpay_logo"
                                                                src={`https://photos.merchlook.com/financing/${shop.shop_bnpl_vendors[0]}_logo.png`}
                                                            ></img>
                                                        ) : (
                                                            <span></span>
                                                        )}
                                                    </span>
                                                </span>
                                            )}
                                        </Menu.Item>
                                    </Menu.Items>
                                </Menu>
                            )}
                        </p>
                        <p className="sui-result-content-shop-paragraphs last-refresh">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#000"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="last-processed"
                            >
                                <circle cx="12" cy="12" r="10"></circle>
                                <polyline points="12 6 12 12 16 14"></polyline>
                            </svg>
                            Cached{' '}
                            {shop.lastsync === undefined
                                ? `${moment.unix(product.updated_at).fromNow()}`
                                : `${moment.unix(shop.lastsync).add(20, 'months').fromNow()}`}
                        </p>
                        {product.is_onsale === 1 && (
                            <p className="sui-result-content-shop-paragraphs">
                                <span className="sui-result-content-discounts sui-producttags">
                                    Save{' '}
                                    {Math.floor(product.discount_percent * 100)}
                                    % ($
                                    {numbro(product[`discount_dollars`]).format(
                                        {
                                            thousandSeparated: true,
                                            mantissa: 2,
                                            optionalMantissa: true,
                                        }
                                    )}
                                    )
                                </span>
                            </p>
                        )}

                        {product.is_available === 0 && (
                            <p className="sui-result-content-shop-paragraphs">
                                <span className="sui-result-content-stock sui-producttags">
                                    Sold Out
                                </span>
                            </p>
                        )}
                    </div>
                </div>
            </div>

            <div className="sui-result-content-mobile">
                <p>
                    <span className="igname-product-type-container">
                        <a
                            href={`/?q=%2B%40${shop.shop_ig_user}&size=n_${resultsPerPage}_n`}
                            className={
                                shop.shop_is_verified === 1
                                    ? `igname-container igname-container-verified`
                                    : 'igname-container'
                            }
                        >
                            @{shop.shop_ig_user.slice(0, 15)}
                            {shop.shop_ig_user.length > 15 && '...'}
                        </a>
                        {shop.shop_is_verified === 1 && (
                            <svg
                                className="sui-result-content-verified"
                                width="16"
                                height="16"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.4193 7L11.9959 5.3725L12.1943 3.22L10.0884 2.74167L8.98594 0.875L7.0026 1.72667L5.01927 0.875L3.91677 2.73583L1.81094 3.20833L2.00927 5.36667L0.585938 7L2.00927 8.6275L1.81094 10.7858L3.91677 11.2642L5.01927 13.125L7.0026 12.2675L8.98594 13.1192L10.0884 11.2583L12.1943 10.78L11.9959 8.6275L13.4193 7ZM5.88844 9.75333L3.67177 7.53083L4.5351 6.6675L5.88844 8.02667L9.30094 4.6025L10.1643 5.46583L5.88844 9.75333Z"
                                    fill="#4a98e2"
                                />
                            </svg>
                        )}
                        <svg
                            className="igname-product-type-container-separator"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M9 18l6-6-6-6" />
                        </svg>
                        <a
                            className="product-type-container"
                            /*onClick={() => {
                                        setSearchTerm(`"${shop.shop_ig_user}"`)
                                        setFilter(
                                            'product_type',
                                            product.product_type
                                        )
                                    }}*/
                            href={`/?q=%2B%40${
                                shop.shop_ig_user
                            }&size=n_${resultsPerPage}_n&filters%5B0%5D%5Bfield%5D=product_type&filters%5B0%5D%5Bvalues%5D%5B0%5D=${product.product_type.replace(
                                /&/gi,
                                '%26'
                            )}&filters%5B0%5D%5Btype%5D=all`}
                        >
                            {product.product_type.toLowerCase().split('-').join(' ').slice(0, 30)}
                            {product.product_type.length > 30 && '...'}
                        </a>
                        <svg
                            className="igname-product-type-container-separator"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M9 18l6-6-6-6" />
                        </svg>
                        <a
                            className="product-type-container"
                            /*onClick={() => {
                                        setSearchTerm(`"${shop.shop_ig_user}"`)
                                        setFilter(
                                            'product_type',
                                            product.product_type
                                        )
                                    }}*/
                            href={`/?q="${
                                product.vendor
                            }"&size=n_${resultsPerPage}_n&filters%5B0%5D%5Bfield%5D=product_type&filters%5B0%5D%5Bvalues%5D%5B0%5D=${product.product_type.replace(
                                /&/gi,
                                '%26'
                            )}&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=vendor&filters%5B1%5D%5Bvalues%5D%5B0%5D=${product.vendor
                                .replace(/(&#.*;)/gi, '')
                                .replace(/\+/gi, '%2B')
                                .replace(
                                    /&/gi,
                                    '%26'
                                )}&filters%5B1%5D%5Btype%5D=any`}
                        >
                            {product.vendor.toLowerCase() !== 'vendor-unknown'
                                ? decodeHTML(
                                      product.vendor.toLowerCase().slice(0, 30)
                                  )
                                : decodeHTML(
                                      shop.shop_title.toLowerCase().slice(0, 30)
                                  )}
                            {product.vendor.length > 30 && '...'}
                        </a>
                    </span>
                </p>

                <a
                    href={product.product_link}
                    onTouchEnd={() => {
                        trackClickThrough(id, ['mobile'])
                    }}
                    className="sui-result-content-title"
                >
                    <span>
                        {product.title.slice(0, 70)}
                        {product.title.length > 70 && '...'}
                    </span>
                </a>

                <p>
                    {parse(
                        `<span>${
                            body_short_modified.length > 0
                                ? body_short_modified
                                      .join('')
                                      .concat(
                                          product.body_short.length < 2 ||
                                              product.body_short.length < 140
                                              ? ''
                                              : '...'
                                      )
                                : body_short_sliced.concat(
                                      product.body_short.length < 2 ||
                                          product.body_short.length < 140
                                          ? ''
                                          : '...'
                                  )
                        }</span>`
                    )}
                </p>
            </div>

            <div className="sui-result-image sui-result-image-desktop">
                <Menu>
                    <Menu.Button
                        className="sui-result-content-productlogo-button"
                        as="div"
                    >
                        {product.is_bestselling === 1 && (
                            <span className="bestseller-tag">Hot Pick 🔥</span>
                        )}

                        {options_count.length > 0 && (
                            <svg
                                className="multi-options-tag"
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#fff"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <rect x="3" y="3" width="7" height="7"></rect>
                                <rect x="14" y="3" width="7" height="7"></rect>
                                <rect x="14" y="14" width="7" height="7"></rect>
                                <rect x="3" y="14" width="7" height="7"></rect>
                            </svg>
                        )}
                        <img
                            className="sui-result-image-productimage"
                            src={
                                product.images.length !== 0
                                    ? product.images[0].src
                                    : 'https://photos.merchlook.com/shop-logos/noimage.png'
                            }
                            alt={'product-image'}
                        />
                    </Menu.Button>
                    <Transition
                        as={React.Fragment}
                        enter="transition ease-out duration-250"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="sui-result-content-product-pages">
                            {options_count.length > 0 && (
                                <div className="product-pages-info">
                                    <span className="product-pages-title">
                                        <svg
                                            className="multi-options-tag-productinfo"
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="18"
                                            height="18"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#fff"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <rect
                                                x="3"
                                                y="3"
                                                width="7"
                                                height="7"
                                            ></rect>
                                            <rect
                                                x="14"
                                                y="3"
                                                width="7"
                                                height="7"
                                            ></rect>
                                            <rect
                                                x="14"
                                                y="14"
                                                width="7"
                                                height="7"
                                            ></rect>
                                            <rect
                                                x="3"
                                                y="14"
                                                width="7"
                                                height="7"
                                            ></rect>
                                        </svg>
                                        Options
                                    </span>
                                    {'options_hashmap' in product &&
                                        product.options_hashmap !== undefined &&
                                        Object.keys(
                                            product.options_hashmap
                                        ).map((option) => {
                                            let alt_key = capitalize(
                                                option.toLowerCase()
                                            )
                                            return (
                                                <div
                                                    className="options-container"
                                                    key={option}
                                                >
                                                    <div className="options-title">
                                                        {alt_key}
                                                    </div>
                                                    <div>
                                                        {product.options_hashmap[
                                                            option
                                                        ]
                                                            .slice(0, 7)
                                                            .map((key) => {
                                                                return (
                                                                    <span
                                                                        className="options-tags"
                                                                        key={
                                                                            key
                                                                        }
                                                                    >
                                                                        {key}
                                                                    </span>
                                                                )
                                                            })}
                                                        {product
                                                            .options_hashmap[
                                                            option
                                                        ].length > 7 && (
                                                            <span className="options-tags">
                                                                and more...
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                </div>
                            )}
                            <div className="product-pages-links">
                                <Menu.Item>
                                    {({ active }) => (
                                        <a href={product.product_link}>
                                            <div className="product-pages-item">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="#c2995b"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0" />
                                                </svg>
                                                <span className="product-pages-item-text">
                                                    open item
                                                </span>
                                            </div>
                                        </a>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({ active }) => (
                                        <a
                                            href={`?q=${keywordextract.run(
                                                product,
                                                shop.shop_title
                                            )} -${
                                                shop.shop_ig_user
                                            }&size=n_${resultsPerPage}_n`}
                                        >
                                            <div className="product-pages-item green-page">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="#7cb683"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <circle
                                                        cx="11"
                                                        cy="11"
                                                        r="8"
                                                    ></circle>
                                                    <line
                                                        x1="21"
                                                        y1="21"
                                                        x2="16.65"
                                                        y2="16.65"
                                                    ></line>
                                                </svg>
                                                <span className="product-pages-item-text">
                                                    show related items
                                                </span>
                                            </div>
                                        </a>
                                    )}
                                </Menu.Item>

                                <Menu.Item>
                                    {({ active }) => (
                                        <span
                                            onClick={() => {
                                                toast(`Access Denied`, {
                                                    icon: '🐮',
                                                    style: {
                                                        borderRadius: '10px',
                                                        background: '#333',
                                                        color: '#fff',
                                                        fontWeight: 'bold',
                                                    },
                                                })
                                            }}
                                        >
                                            <div className="product-pages-item page-disabled">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="#fff"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <path d="M22 17H2a3 3 0 0 0 3-3V9a7 7 0 0 1 14 0v5a3 3 0 0 0 3 3zm-8.27 4a2 2 0 0 1-3.46 0"></path>
                                                </svg>
                                                <span className="product-pages-item-text">
                                                    watch item
                                                </span>
                                            </div>
                                        </span>
                                    )}
                                </Menu.Item>

                                <Menu.Item>
                                    {({ active }) => (
                                        <span>
                                            <div className="product-pages-item close-page">
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    viewBox="0 0 24 24"
                                                    fill="none"
                                                    stroke="#000"
                                                    strokeWidth="2"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                >
                                                    <circle
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                    ></circle>
                                                    <line
                                                        x1="15"
                                                        y1="9"
                                                        x2="9"
                                                        y2="15"
                                                    ></line>
                                                    <line
                                                        x1="9"
                                                        y1="9"
                                                        x2="15"
                                                        y2="15"
                                                    ></line>
                                                </svg>
                                                <span className="product-pages-item-text">
                                                    close
                                                </span>
                                            </div>
                                        </span>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
            <div className="sui-result-content sui-result-content-desktop">
                <div>
                    <span className="sui-result-content-shop sui-desktop-flex">
                        <Menu>
                            <Menu.Button className="sui-result-content-shoplogo-button">
                                <img
                                    className={shop.shop_gotdeals ? `sui-result-content-shoplogo shoplogo-gotdeals` : `sui-result-content-shoplogo`}
                                    src={`https://photos.merchlook.com/shop-logos/${shop.shop_ig_user}.jpg`}
                                />
                            </Menu.Button>
                            <Transition
                                as={React.Fragment}
                                enter="transition ease-out duration-250"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="sui-result-content-shop-pages">
                                    <div className="shop-pages-links">
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href={`https://tiktok.com/search?q=${shop.shop_ig_user}`}
                                                >
                                                    <div className="shop-pages-item">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#84a7c4"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <rect
                                                                x="2"
                                                                y="2"
                                                                width="20"
                                                                height="20"
                                                                rx="2.18"
                                                                ry="2.18"
                                                            ></rect>
                                                            <line
                                                                x1="7"
                                                                y1="2"
                                                                x2="7"
                                                                y2="22"
                                                            ></line>
                                                            <line
                                                                x1="17"
                                                                y1="2"
                                                                x2="17"
                                                                y2="22"
                                                            ></line>
                                                            <line
                                                                x1="2"
                                                                y1="12"
                                                                x2="22"
                                                                y2="12"
                                                            ></line>
                                                            <line
                                                                x1="2"
                                                                y1="7"
                                                                x2="7"
                                                                y2="7"
                                                            ></line>
                                                            <line
                                                                x1="2"
                                                                y1="17"
                                                                x2="7"
                                                                y2="17"
                                                            ></line>
                                                            <line
                                                                x1="17"
                                                                y1="17"
                                                                x2="22"
                                                                y2="17"
                                                            ></line>
                                                            <line
                                                                x1="17"
                                                                y1="7"
                                                                x2="22"
                                                                y2="7"
                                                            ></line>
                                                        </svg>

                                                        <span className="shop-pages-item-text tiktok-page">
                                                            open tiktok
                                                        </span>
                                                    </div>
                                                </a>
                                            )}
                                        </Menu.Item>

                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    href={`https://instagram.com/${shop.shop_ig_user}`}
                                                >
                                                    <div className="shop-pages-item">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#84a7c4"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <rect
                                                                x="3"
                                                                y="3"
                                                                width="18"
                                                                height="18"
                                                                rx="2"
                                                            />
                                                            <circle
                                                                cx="8.5"
                                                                cy="8.5"
                                                                r="1.5"
                                                            />
                                                            <path d="M20.4 14.5L16 10 4 20" />
                                                        </svg>
                                                        <span className="shop-pages-item-text instagram-page">
                                                            open instagram
                                                        </span>
                                                    </div>
                                                </a>
                                            )}
                                        </Menu.Item>

                                        <Menu.Item>
                                            <a
                                                href={`/?q=%2B%40${shop.shop_ig_user}&size=n_${resultsPerPage}_n`}
                                            >
                                                <div className="shop-pages-item green-page">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#5f83a1"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <circle
                                                            cx="11"
                                                            cy="11"
                                                            r="8"
                                                        ></circle>
                                                        <line
                                                            x1="21"
                                                            y1="21"
                                                            x2="16.65"
                                                            y2="16.65"
                                                        ></line>
                                                    </svg>

                                                    <span className="shop-pages-item-text">
                                                        show all products
                                                    </span>
                                                </div>
                                            </a>
                                        </Menu.Item>

                                        {shop.shop_gotdeals === 0 ? (
                                            <Menu.Item>
                                                <span
                                                    onClick={() => {
                                                        toast(
                                                            `No deals found for @${shop.shop_ig_user}`,
                                                            {
                                                                icon: '🐮',
                                                                style: {
                                                                    borderRadius:
                                                                        '10px',
                                                                    background:
                                                                        '#333',
                                                                    color: '#fff',
                                                                    fontWeight:
                                                                        'bold',
                                                                },
                                                            }
                                                        )
                                                    }}
                                                >
                                                    <div
                                                        className={
                                                            'shop-pages-item page-disabled'
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#5f83a1"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <circle
                                                                cx="11"
                                                                cy="11"
                                                                r="8"
                                                            ></circle>
                                                            <line
                                                                x1="21"
                                                                y1="21"
                                                                x2="16.65"
                                                                y2="16.65"
                                                            ></line>
                                                        </svg>

                                                        <span className="shop-pages-item-text">
                                                            show deals
                                                        </span>
                                                    </div>
                                                </span>
                                            </Menu.Item>
                                        ) : (
                                            <Menu.Item>
                                                <a
                                                    href={`?q=%2B%40${shop.shop_ig_user}&size=n_${resultsPerPage}_n&filters%5B0%5D%5Bfield%5D=is_onsale&filters%5B0%5D%5Bvalues%5D%5B0%5D=n_1_n&filters%5B0%5D%5Btype%5D=any`}
                                                >
                                                    <div
                                                        className={
                                                            'shop-pages-item deals-page'
                                                        }
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="16"
                                                            height="16"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#5f83a1"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <circle
                                                                cx="11"
                                                                cy="11"
                                                                r="8"
                                                            ></circle>
                                                            <line
                                                                x1="21"
                                                                y1="21"
                                                                x2="16.65"
                                                                y2="16.65"
                                                            ></line>
                                                        </svg>

                                                        <span className="shop-pages-item-text">
                                                            show deals
                                                        </span>
                                                    </div>
                                                </a>
                                            </Menu.Item>
                                        )}

                                        {shop.shop_pages &&
                                            sortForTrackReturns(Object.keys(shop.shop_pages)).map(
                                                (pageTitle) => {
                                                    return (
                                                        <Menu.Item
                                                            key={pageTitle}
                                                        >
                                                            {({ active }) => (
                                                                <a
                                                                    href={
                                                                        shop
                                                                            .shop_pages[
                                                                            pageTitle
                                                                        ]
                                                                    }
                                                                >
                                                                    <div className="shop-pages-item">
                                                                        {pageTitle ===
                                                                        'track-returns' ? (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="#c2995b"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                <path d="M17 2.1l4 4-4 4" />
                                                                                <path d="M3 12.2v-2a4 4 0 0 1 4-4h12.8M7 21.9l-4-4 4-4" />
                                                                                <path d="M21 11.8v2a4 4 0 0 1-4 4H4.2" />
                                                                            </svg>
                                                                        ) : (
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="16"
                                                                                height="16"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="#c2995b"
                                                                                strokeWidth="2"
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                            >
                                                                                <path d="M14 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V8l-6-6z" />
                                                                                <path d="M14 3v5h5M16 13H8M16 17H8M10 9H8" />
                                                                            </svg>
                                                                        )}
                                                                        <span className="shop-pages-item-text">
                                                                            {pageTitle.replace(
                                                                                /-/g,
                                                                                ' '
                                                                            )}
                                                                        </span>
                                                                    </div>
                                                                </a>
                                                            )}
                                                        </Menu.Item>
                                                    )
                                                }
                                            )}

                                        <Menu.Item>
                                            <span
                                                onClick={() => {
                                                    toast(`Access Denied`, {
                                                        icon: '🐮',
                                                        style: {
                                                            borderRadius:
                                                                '10px',
                                                            background: '#333',
                                                            color: '#fff',
                                                            fontWeight: 'bold',
                                                        },
                                                    })
                                                }}
                                            >
                                                <div className="shop-pages-item page-disabled">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#555"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M21.21 15.89A10 10 0 1 1 8 2.83"></path>
                                                        <path d="M22 12A10 10 0 0 0 12 2v10z"></path>
                                                    </svg>
                                                    <span className="shop-pages-item-text">
                                                        show stats
                                                    </span>
                                                </div>
                                            </span>
                                        </Menu.Item>

                                        <Menu.Item>
                                            <a
                                                href={`https://ig.me/m/${shop.shop_ig_user}`}
                                            >
                                                <div className="shop-pages-item livechat-page">
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="16"
                                                        height="16"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        stroke="#ae7ea7"
                                                        strokeWidth="2"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    >
                                                        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
                                                    </svg>
                                                    <span className="shop-pages-item-text">
                                                        live chat
                                                    </span>
                                                </div>
                                            </a>
                                        </Menu.Item>
                                    </div>
                                    {/* change below to shop_desc*/}
                                    {shop.shop_tags !== undefined && (
                                        <div className="shop-pages-info">
                                            <div>
                                                <div className="shop-pages-shoplogo">
                                                    <img
                                                        className="sui-result-content-shoplogo shopinfo-shoplogo-big"
                                                        src={`https://photos.merchlook.com/shop-logos/${shop.shop_ig_user}.jpg`}
                                                    />
                                                </div>
                                                <Menu.Item>
                                                    <div className="shop-pages-closebutton">
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            className="closebutton-svg"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#ff605c"
                                                            strokeWidth="2"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="10"
                                                            ></circle>
                                                            <line
                                                                x1="15"
                                                                y1="9"
                                                                x2="9"
                                                                y2="15"
                                                            ></line>
                                                            <line
                                                                x1="9"
                                                                y1="9"
                                                                x2="15"
                                                                y2="15"
                                                            ></line>
                                                        </svg>
                                                    </div>
                                                </Menu.Item>
                                            </div>
                                            <div className="shop-card-content">
                                                <div className="shopinfo-bio">
                                                    <strong>
                                                        <span className="shop-info-blacktag">
                                                            @
                                                            {shop.shop_ig_user
                                                                .length > 14
                                                                ? `${shop.shop_ig_user.slice(
                                                                      0,
                                                                      14
                                                                  )}...`
                                                                : shop.shop_ig_user}
                                                            {shop.shop_is_verified ===
                                                                1 && (
                                                                <svg
                                                                    className="sui-result-content-verified verified-badge-shopinfo"
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 14 14"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M13.4193 7L11.9959 5.3725L12.1943 3.22L10.0884 2.74167L8.98594 0.875L7.0026 1.72667L5.01927 0.875L3.91677 2.73583L1.81094 3.20833L2.00927 5.36667L0.585938 7L2.00927 8.6275L1.81094 10.7858L3.91677 11.2642L5.01927 13.125L7.0026 12.2675L8.98594 13.1192L10.0884 11.2583L12.1943 10.78L11.9959 8.6275L13.4193 7ZM5.88844 9.75333L3.67177 7.53083L4.5351 6.6675L5.88844 8.02667L9.30094 4.6025L10.1643 5.46583L5.88844 9.75333Z"
                                                                        fill="#4a98e2"
                                                                    />
                                                                </svg>
                                                            )}
                                                        </span>
                                                        <span className="followers-container">
                                                            <span>
                                                                <svg
                                                                    className="followers-logo followers-logo-shopinfo"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="14"
                                                                    height="14"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="#fff"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                >
                                                                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                                                                    <circle
                                                                        cx="12"
                                                                        cy="7"
                                                                        r="4"
                                                                    ></circle>
                                                                </svg>{' '}
                                                                <strong>
                                                                    {followers}
                                                                </strong>
                                                            </span>
                                                        </span>
                                                    </strong>

                                                    <div className="shop-info-blacktag shop-info-locationtag">
                                                        <svg
                                                            className="location-logo location-logo-shopinfo"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="14"
                                                            height="14"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="#fff"
                                                            strokeWidth="3"
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                        >
                                                            <circle
                                                                cx="12"
                                                                cy="10"
                                                                r="3"
                                                            />
                                                            <path d="M12 21.7C17.3 17 20 13 20 10a8 8 0 1 0-16 0c0 3 2.7 6.9 8 11.7z" />
                                                        </svg>

                                                        {RenderCountry(
                                                            shop.shop_currency
                                                        )}
                                                    </div>
                                                </div>

                                                {shop.shop_desc !==
                                                undefined ? (
                                                    <p className="shopinfo-shopdesc">
                                                        {shop.shop_desc[
                                                            shop.shop_desc
                                                                .length - 1
                                                        ] !== '.'
                                                            ? `
                                                          ${shop.shop_desc.slice(
                                                              0,
                                                              180
                                                          )}...`
                                                            : `
                                                          ${shop.shop_desc.slice(
                                                              0,
                                                              180
                                                          )}..`}
                                                    </p>
                                                ) : (
                                                    <p className="shopinfo-shopdesc">
                                                        {shop.shop_title !==
                                                        undefined
                                                            ? shop.shop_title
                                                            : '...'}
                                                    </p>
                                                )}

                                                <div className="shopinfo-tags">
                                                    {Object.keys(shop.shop_tags)
                                                        .length !== 0 && (
                                                        <div>
                                                            <strong className="populartag shop-info-blackfont">
                                                                <svg
                                                                    className="barchart-logo"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    width="16"
                                                                    height="16"
                                                                    viewBox="0 0 24 24"
                                                                    fill="none"
                                                                    stroke="#000"
                                                                    strokeWidth="3"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                >
                                                                    <path d="M20.2 7.8l-7.7 7.7-4-4-5.7 5.7" />
                                                                    <path d="M15 7h6v6" />
                                                                </svg>
                                                                Top matches on{' '}
                                                                {shop.shop_ig_count <
                                                                50000
                                                                    ? 'Instagram'
                                                                    : 'Tiktok'}
                                                            </strong>
                                                        </div>
                                                    )}
                                                    <div className="shoptags-container-list">
                                                        {Object.keys(
                                                            shop.shop_tags
                                                        )
                                                            .sort(function (
                                                                a,
                                                                b
                                                            ) {
                                                                return (
                                                                    shop
                                                                        .shop_tags[
                                                                        b
                                                                    ] -
                                                                    shop
                                                                        .shop_tags[
                                                                        a
                                                                    ]
                                                                )
                                                            })
                                                            .map(
                                                                (
                                                                    item,
                                                                    index
                                                                ) => {
                                                                    /*
                                                            if (index === 0) {
                                                                return (
                                                                    <span className="shoptags-container-list-span">
                                                                        🥇{' '}
                                                                        {item}
                                                                    </span>
                                                                )
                                                            }

                                                            if (index === 1) {
                                                                return (
                                                                    <span className="shoptags-container-list-span">
                                                                        🥈{' '}
                                                                        {item}
                                                                    </span>
                                                                )
                                                            }

                                                            if (index === 2) {
                                                                return (
                                                                    <span className="shoptags-container-list-span">
                                                                        🥉{' '}
                                                                        {item}
                                                                    </span>
                                                                )
                                                            }
															*/
                                                                    return (
                                                                        <span
                                                                            className="shoptags-container-list-span"
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            {
                                                                                item
                                                                            }
                                                                        </span>
                                                                    )
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                        <span>
                            <a
                                href={product.product_link}
                                onMouseUp={(e) => {
                                    //track leftclick, middleclick, rightclick
                                    if (
                                        e.button === 0 ||
                                        e.button === 1 ||
                                        e.button === 2
                                    ) {
                                        trackClickThrough(id, ['desktop'])
                                    }
                                }}
                                className="sui-result-content-title"
                            >
                                <span>
                                    {product.title.slice(0, 93)}
                                    {product.title.length > 93 && '...'}
                                </span>
                            </a>
                        </span>
                    </span>
                </div>

                <p>
                    <span>
                        <svg
                            className="followers-logo"
                            xmlns="http://www.w3.org/2000/svg"
                            width="14"
                            height="14"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                            <circle cx="12" cy="7" r="4"></circle>
                        </svg>{' '}
                        <strong>{followers}</strong>
                    </span>

                    <span className="igname-product-type-container igname-product-type-container-desktop">
                        <a
                            href={`/?q=%2B%40${shop.shop_ig_user}&size=n_${resultsPerPage}_n`}
                            className={
                                shop.shop_is_verified === 1
                                    ? `igname-container igname-container-verified`
                                    : 'igname-container'
                            }
                        >
                            @{shop.shop_ig_user.slice(0, 20)}
                            {shop.shop_ig_user.length > 20 && '...'}
                        </a>
                        {shop.shop_is_verified === 1 && (
                            <svg
                                className="sui-result-content-verified"
                                width="16"
                                height="16"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M13.4193 7L11.9959 5.3725L12.1943 3.22L10.0884 2.74167L8.98594 0.875L7.0026 1.72667L5.01927 0.875L3.91677 2.73583L1.81094 3.20833L2.00927 5.36667L0.585938 7L2.00927 8.6275L1.81094 10.7858L3.91677 11.2642L5.01927 13.125L7.0026 12.2675L8.98594 13.1192L10.0884 11.2583L12.1943 10.78L11.9959 8.6275L13.4193 7ZM5.88844 9.75333L3.67177 7.53083L4.5351 6.6675L5.88844 8.02667L9.30094 4.6025L10.1643 5.46583L5.88844 9.75333Z"
                                    fill="#4a98e2"
                                />
                            </svg>
                        )}

                        <svg
                            className="igname-product-type-container-separator"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M9 18l6-6-6-6" />
                        </svg>
                        <a
                            className="product-type-container"
                            /*onClick={() => {
                                        setSearchTerm(`"${shop.shop_ig_user}"`)
                                        setFilter(
                                            'product_type',
                                            product.product_type
                                        )
                                    }}*/
                            href={`/?q=%2B%40${
                                shop.shop_ig_user
                            }&size=n_${resultsPerPage}_n&filters%5B0%5D%5Bfield%5D=product_type&filters%5B0%5D%5Bvalues%5D%5B0%5D=${product.product_type.replace(
                                /&/gi,
                                '%26'
                            )}&filters%5B0%5D%5Btype%5D=all`}
                        >
                            {product.product_type.toLowerCase().split('-').join(' ').slice(0, 30)}
                            {product.product_type.length > 30 && '...'}
                        </a>
                        <svg
                            className="igname-product-type-container-separator"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            strokeWidth="3"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M9 18l6-6-6-6" />
                        </svg>
                        <a
                            className="product-type-container"
                            href={`/?q="${
                                product.vendor
                            }"&size=n_${resultsPerPage}_n&filters%5B0%5D%5Bfield%5D=product_type&filters%5B0%5D%5Bvalues%5D%5B0%5D=${product.product_type.replace(
                                /&/gi,
                                '%26'
                            )}&filters%5B0%5D%5Btype%5D=all&filters%5B1%5D%5Bfield%5D=vendor&filters%5B1%5D%5Bvalues%5D%5B0%5D=${product.vendor
                                .replace(/(&#.*;)/gi, '')
                                .replace(/\+/gi, '%2B')
                                .replace(
                                    /&/gi,
                                    '%26'
                                )}&filters%5B1%5D%5Btype%5D=any`}
                        >
                            {product.vendor.toLowerCase() !== 'vendor-unknown'
                                ? decodeHTML(
                                      product.vendor.toLowerCase().slice(0, 30)
                                  )
                                : decodeHTML(
                                      shop.shop_title.toLowerCase().slice(0, 30)
                                  )}
                            {product.vendor.length > 30 && '...'}
                        </a>
                    </span>
                </p>

                <p>
                    <span className="sui-result-content-price">
                        $
                        {numbro(
                            product[`min_price_${userCountry.toLowerCase()}`]
                        ).format({
                            mantissa: 2,
                            thousandSeparated: true,
                            optionalMantissa: true,
                        })}{' '}
                        {userCountry}
                        {Object.keys(product.options_count).length > 0 &&
                            Object.keys(product.options_count)[0] !== 'Title' &&
                            product.options_count[
                                Object.keys(product.options_count)[0]
                            ] > 1 &&
                            '+'}{' '}
                    </span>
                    <span className="last-change-desktop">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="last-processed"
                        >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                        </svg>
                        Cached{' '}
                        {shop.lastsync === undefined
                            ? `${moment.unix(product.updated_at).fromNow()}`
                            : `${moment.unix(shop.lastsync).add(20, 'months').fromNow()}`}
                    </span>

                    {product.is_onsale === 1 && (
                        <span className="sui-result-content-discounts sui-producttags">
                            Save {Math.floor(product.discount_percent * 100)}%
                            ($
                            {numbro(product[`discount_dollars`]).format({
                                mantissa: 2,
                                thousandSeparated: true,
                                optionalMantissa: true,
                            })}
                            )
                        </span>
                    )}

                    {product.is_available === 0 && (
                        <span className="sui-result-content-stock sui-producttags">
                            Sold Out
                        </span>
                    )}

                    {shop.shop_financing === 1 && (
                        <Menu>
                            <Menu.Button className="sui-result-content-financing-button">
                                <svg
                                    className="financing-tooltip"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="14"
                                    height="14"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="#8C86FC"
                                    strokeWidth="3"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                >
                                    <rect
                                        x="2"
                                        y="4"
                                        width="20"
                                        height="16"
                                        rx="2"
                                    />
                                    <path d="M7 15h0M2 9.5h20" />
                                </svg>
                            </Menu.Button>
                            <Menu.Items
                                className="sui-result-content-financing-dropdown"
                                as="span"
                            >
                                <Menu.Item>
                                    {({ active }) => (
                                        <span className="financing-dropdown-text">
                                            <span>
                                                <span className="financing-apr-text">
                                                    0% APR payment plans
                                                </span>
                                                {shop.shop_bnpl_vendors !==
                                                    undefined &&
                                                shop.shop_bnpl_vendors.length >
                                                    0 ? (
                                                    <img
                                                        className={
                                                            shop
                                                                .shop_bnpl_vendors[0] ===
                                                            'affirm'
                                                                ? 'bnpl-vendor-logo affirm-logo'
                                                                : `bnpl-vendor-logo`
                                                        }
                                                        alt="afterpay_logo"
                                                        src={`https://photos.merchlook.com/financing/${shop.shop_bnpl_vendors[0]}_logo.png`}
                                                    ></img>
                                                ) : (
                                                    <span></span>
                                                )}
                                            </span>
                                        </span>
                                    )}
                                </Menu.Item>
                            </Menu.Items>
                        </Menu>
                    )}
                </p>
                <p>
                    {parse(
                        `<span>${
                            body_short_modified.length > 0
                                ? body_short_modified
                                      .join('')
                                      .concat(
                                          product.body_short.length < 2 ||
                                              product.body_short.length < 140
                                              ? ''
                                              : '...'
                                      )
                                : body_short_sliced.concat(
                                      product.body_short.length < 2 ||
                                          product.body_short.length < 140
                                          ? ''
                                          : '...'
                                  )
                        }</span>`
                    )}
                </p>
            </div>
        </div>
    )
}

/* References:
 *
 * https://codesandbox.io/embed/video-games-tutorial-with-images-5wsqs
 *
 * https://codesandbox.io/embed/search-ui-national-parks-example-2ph4n
 */
