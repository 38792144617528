import React from 'react'
import { Menu } from '@headlessui/react'

export default function NavBar() {
    return (
        <div className={'navbar'}>
            <Menu>
                <span className="menubutton-wrapper">
                    <Menu.Button className="navbar-menubutton header--filled-link">
                        <span>
                            <svg
                                className="hamburger-logo"
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#fff"
                                strokeWidth="3"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            >
                                <line x1="3" y1="12" x2="21" y2="12"></line>
                                <line x1="3" y1="6" x2="21" y2="6"></line>
                                <line x1="3" y1="18" x2="21" y2="18"></line>
                            </svg>{' '}
                            merchlook
                        </span>
                    </Menu.Button>
                    <Menu.Items className="navbarmenu-pages">
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="/about"
                                    onClick={() => {
                                        document.title = 'About - Merchlook'
                                    }}
                                >
                                    <div className="navbarmenu-pages-item">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#fff"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <circle
                                                cx="12"
                                                cy="12"
                                                r="10"
                                            ></circle>
                                            <line
                                                x1="12"
                                                y1="16"
                                                x2="12"
                                                y2="12"
                                            ></line>
                                            <line
                                                x1="12"
                                                y1="8"
                                                x2="12.01"
                                                y2="8"
                                            ></line>
                                        </svg>
                                        <span className="navbarmenu-item-text">
                                            about
                                        </span>
                                    </div>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="/tips"
                                    onClick={() => {
                                        document.title = 'Tips - Merchlook'
                                    }}
                                >
                                    <div className="navbarmenu-pages-item">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#fff"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                        </svg>
                                        <span className="navbarmenu-item-text">
                                            tips
                                        </span>
                                    </div>
                                </a>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href="/login"
                                    onClick={() => {
                                        document.title = 'Login - Merchlook'
                                    }}
                                >
                                    <div className="navbarmenu-pages-item">
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="16"
                                            height="16"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            stroke="#fff"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        >
                                            <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M13.8 12H3" />
                                        </svg>
                                        <span className="navbarmenu-item-text">
                                            login
                                        </span>
                                    </div>
                                </a>
                            )}
                        </Menu.Item>

                    </Menu.Items>
                </span>
            </Menu>

            <button
                className="privacyButton"
                onClick={() => {
                    window.location.href = '/login'
                }}
            >
                    <span className="privacy-box-container-on">
                        <svg
                            className="privacy-logo"
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#000000"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <rect
                                x="3"
                                y="11"
                                width="18"
                                height="11"
                                rx="2"
                                ry="2"
                            ></rect>
                            <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
                        </svg>
                    </span>
               Unlock Pro Version
            </button>
        </div>
    )
}
