import React from 'react'

export default function AboutPage(props) {
    return (
        <div
            className={
                props.isLoading || props.wasSearched
                    ? 'aboutpage-hide'
                    : 'aboutpage'
            }
        >
            <div className="aboutpage-title-container">
                <img
                    src="./apple-touch-icon.png"
                    width="70"
                    height="70"
                    className="aboutpage-title-container-logo"
                />
                <div>
                    <h2 className="aboutpage-maintitle">
                        Save time and money with our shopping tool
                    </h2>
                    <h4 className="aboutpage-subtitle">
                        We scan the hottest brands on TikTok and Instagram for shopping deals
                    </h4>
                </div>
            </div>

            <p className="about-blurb">
                Merchlook is a search engine for finding shopping deals on TikTok and Instagram, including 
                apparel, jewelry, home decor, kitchenware, foods,
                toys, skincare, and makeup.
            </p>

		<p className="about-blurb">Made by <a href="mailto:tkclai@protonmail.com">TK Lai</a></p>
        </div>
    )
}
